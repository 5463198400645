<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-row>
          <v-col cols="7" md="9"
            ><v-card-title>Product Stock Summary</v-card-title></v-col
          >

          <v-col cols="5" md="3">
            <v-card-title>
              <v-select
                v-model="selectBranch"
                :items="branchList"
                item-text="name"
                item-value="branch_id"
                outlined
                dense
              ></v-select
            ></v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <base-card>
              <v-card-title>
                <v-row class="mt-n15">
                  <v-col cols="11">
                    <v-text-field
                      v-model="productSearch"
                      append-icon="mdi-magnify"
                      label="Search product name or SKU"
                      single-line
                      hide-details
                    ></v-text-field
                  ></v-col>

                  <v-col cols="1">
                    <div v-if="productStockList.length != 0">
                      <export-excel
                        :data="productStockList"
                        :fields="json_fields"
                        :name="filename"
                      >
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mt-5 ml-5" v-bind="attrs" v-on="on">
                              mdi-download
                            </v-icon>
                          </template>
                          <span>Download Product Stock Report</span>
                        </v-tooltip>
                      </export-excel>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :search="productSearch"
                  :headers="headers"
                  :items="productStockList"
                  :page.sync="page"
                  :items-per-page="itemsPerPage"
                  @page-count="pageCount = $event"
                  item-key="sales_id"
                  hide-default-footer
                >
                  <template v-slot:item.stock_amount="{ item }">
                    <span :class="item.stock_amount < 10 ? 'red--text' : ''">{{
                      item.stock_amount
                    }}</span>
                  </template>
                </v-data-table>
                <div class="text-center py-2">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </div>
              </v-card-text>
            </base-card>
          </v-col>
        </v-row>
      </base-card>
    </v-col>
  </v-row>
</template>
      <script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import TestCard from "@/components/card/TestCard";
import PriceCard from "@/components/card/PriceCard";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Sales",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Sales",
  },
  components: {
    "test-card": TestCard,
    "price-card": PriceCard,
    VueHtml2pdf,
  },
  data() {
    return {
      json_fields: {
        "Product Name": "name_with_variant",
        "SKU": "b_SKU",
        "Stock Type": "stock_type_name",
        "Stock Amount": "stock_amount",
      },
      domain: BASEURL,
      company_id: "",
      showIcon: false,
      showChart: false,
      date: "",
      branchList: [],
      productStockList: [],
      selectBranch: "",
      productSearch: "",
      branchName: "",
      headers: [
        {
          text: "Product Name",
          align: "start",
          sortable: true,
          value: "name_with_variant",
        },

        {
          text: "SKU",
          align: "start",
          value: "b_SKU",
          sortable: true,
        },

        {
          text: "Stock Type",
          align: "start",
          sortable: true,
          value: "stock_type_name",
        },

        {
          text: "Stock Amount",
          align: "start",
          value: "stock_amount",
          sortable: true,
        },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  computed: {
    filename() {
      return this.branchName + "'s Product Stock Report.xls";
    },
  },
  watch: {
    selectBranch() {
      for (let i = 0; i < this.branchList.length; i++) {
        if (this.selectBranch == this.branchList[i].branch_id) {
          this.branchName = this.branchList[i].name;
        }
      }
      this.productStockList = [];
      this.getProductStockReport();
    },
  },
  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getCompanyBranch();
  },
  methods: {
    getProductStockReport() {
      const params = new URLSearchParams();
      params.append("getProductStockReport", "done");
      params.append("branch_id", this.selectBranch);

      axios({
        method: "post",
        url: this.domain + "/report/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);

          if (response.data.status == "1") {
            response.data.report.forEach((product) => {
              // Create the name_with_variant field
              product.name_with_variant = product.variant_name
                ? `${product.name} - ${product.variant_name}`
                : product.name;

              // Create the stock_amount field based on stock_type
              if (product.stock_type == "1") {
                product.stock_amount = product.daily_limit;
                product.stock_type_name = "Daily limit";
              } else if (product.stock_type == "2") {
                product.stock_amount = product.stock_quantity;
                product.stock_type_name = "Stock";
              } else if (product.stock_type == "3") {
                product.stock_amount = "-";
                product.stock_type_name = "Unlimited Stock";
              } else {
                product.stock_amount = null; // Optional: Handle cases where stock_type is not 1, 2, or 3
                product.stock_type_name = "Unknown"; // Optional: Handle unknown stock_type
              }
            });
            this.productStockList = response.data.report;
          } else {
            this.productStockList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var moment = require("moment");
            this.branchList = response.data.branch;
            this.selectBranch = this.branchList[0].branch_id;
            this.date = moment().format("YYYY-MM");
            this.getProductStockReport();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
      <style>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: large;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
</style>
      
      
      